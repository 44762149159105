import crypto from 'crypto'
const md5Salt = 'md5_salt_aidioute'
const aesKey = Buffer.from('aes_key_aidioute')
const aesIv = Buffer.from('0102030405060708')
const PublicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAh/QdVFE0//4O62UAlfrW
igeIoZe4JXQHRrUdM7JXSWviBHDhrtOJvnGvDZpM1MY9T31EL8g1PHIJ45+H33Im
lDNplNC4572bXaHP/Wp6PLFv6QSW1bN1dNx4ee+s3HGi+EXBBe27pjQwBhlrVv8L
geYgsZMim6H5CLps9c/Fy7Xk/37NN7EgRJXM9D8wFB5rvzYcz0/4Gl+Vl9G7LTia
aUaCiJOLog3kTp5ZmZGwpMsFLi0pVAKwNGLGuR18iSG5TW2T6VM971c4HvTVQ8Tw
b7Ds//6sCLDGjksYp9mr8NeM9KmiM6R8rCQMOVVoBb5ZjJROfIeVY+4FGr2VE5R+
twIDAQAB
-----END PUBLIC KEY-----`

const md5Encrypt = (str) => {
  return crypto.createHash('md5').update(String(str + md5Salt)).digest('hex')
}

const aesEncrypt = (text) => {
  const buffer = Buffer.from(text)
  const cipher = crypto.createCipheriv('aes-128-cbc', aesKey, aesIv)
  return Buffer.concat([cipher.update(buffer), cipher.final()]).toString('hex').toUpperCase()
}

const rsaEncrypt = (str) => {
  return crypto.publicEncrypt(PublicKey, Buffer.from(str)).toString('base64')
}

const passwordEncrypt = (str) => {
  return rsaEncrypt(md5Encrypt(md5Encrypt(str)))
}

export default {
  md5Encrypt,
  aesEncrypt,
  rsaEncrypt,
  passwordEncrypt
}
